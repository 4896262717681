/* global $ Hammer */

$.fn.carousel = function (randomNumber) {
  $(this).each(function () {
    var $carousel = $(this)
    var $carouselInner = $carousel.find('.c-carousel__inner')
    var $carouselItems = $carouselInner.find('.c-carousel__item')
    var carouselItemsCount = $carouselItems.length
    var $firstChild = $carouselItems.first()
    var $lastChild = $carouselItems.last()
    var $arrowLeft = $carousel.find('.c-carousel__arrow.left')
    var $arrowRight = $carousel.find('.c-carousel__arrow.right')
    var itemWidth = parseInt($carousel.attr('data-item-width')) || 380
    var itemSpacing = 24
    var maxChildHeight = 0
    var $carouselDots = $('<div></div>').addClass('c-carousel__dots')
    var start = carouselItemsCount
    var current = start
    var height = 0
    if (carouselItemsCount) {
      $carousel.append($carouselDots)
      for (var i = 0; i < carouselItemsCount; i++) {
        $carouselDots.append('<div></div>')
      }
      $carouselDots.children().eq(current - carouselItemsCount).addClass('active')
    }
    if (itemWidth > $(window).width() - itemSpacing * 3) {
      itemWidth = $(window).width() - itemSpacing * 3
    }
    $carouselItems.each(function () {
      $(this).width(itemWidth)
    })
    window.view.reset();
    window.view.initialize();

    $carouselItems.each(function (index, item) {
      var $originalItem = $(item)
      var $item = $originalItem.clone()
      $item.addClass('additional')
      $lastChild.parent().append($item.clone())
      $firstChild.before($item.clone())
    })

    var $carouselItemsNew = $carouselInner.find('.c-carousel__item')
    $carouselItemsNew.each(function (i) {
      $(this).transform((itemSpacing + itemWidth) * i)
    })
    /* Start location */
    $carouselInner.moveLeft('-' + (start * (itemSpacing + itemWidth) - itemSpacing))

    var moveToNext = function () {
      current++
      $carouselInner.moveLeft(-(current * (itemWidth + itemSpacing) - itemSpacing))
      $carouselDots.find('.active').removeClass('active')
      $carouselDots.children().eq(current === start + carouselItemsCount ? 0 : current - carouselItemsCount).addClass('active')
      if (current === start + carouselItemsCount) {
        setTimeout(function () {
          current = carouselItemsCount
          $carouselInner.moveLeft(-(current * (itemWidth + itemSpacing) - itemSpacing), 'none')
          $carouselDots.find('.active').removeClass('active')
          $carouselDots.children().first().addClass('active')
          window.view.reset();
          window.view.initialize();
        }, 500)
      }
    }
    var moveToPrevious = function () {
      current--
      $carouselInner.moveLeft(-(current * (itemWidth + itemSpacing) - itemSpacing))
      $carouselDots.find('.active').removeClass('active')
      $carouselDots.children().eq(current === start - 1 ? carouselItemsCount - 1 : current - carouselItemsCount).addClass('active')
      if (current === start - 1) {
        setTimeout(function () {
          current = start + carouselItemsCount - 1
          $carouselInner.moveLeft(-(current * (itemWidth + itemSpacing) - itemSpacing), 'none')
          $carouselDots.find('.active').removeClass('active')
          $carouselDots.children().last().addClass('active')
          window.view.reset();
          window.view.initialize();
        }, 500)
      }
    }
    window.requestAnimationFrame(function () {
      setTimeout(function () {
        window.requestAnimationFrame(function () {
          $carouselItems.each(function () {
            if ($(this).height() > maxChildHeight) {
              maxChildHeight = $(this).height()
            }
          })
          $carouselItems.each(function (index, item) {
            var $originalItem = $(item)
            if ($originalItem.find('.c-card__content').height() > height) {
              height = $originalItem.find('.c-card__content').height()
            }
          })
          $carousel.find('.c-carousel__inner').find('.c-card__content').height(height)
          $carousel.height(maxChildHeight + 20)
          window.view.reset();
          window.view.initialize();
        })
      }, 500)
    })
    var mc = new Hammer($carousel.get(0))
    mc.on('swipeleft', function () {
      moveToNext()
    }).on('swiperight', function () {
      moveToPrevious()
    })

    $arrowLeft.click(function () {
      moveToPrevious()
    })
    $arrowRight.click(function () {
      moveToNext()
    })
    $carousel.onInViewport(function (inview) {
      if (inview) {
        $(document).off('keydown').on('keydown', function (e) {
          if (e.which === 39) {
            moveToNext()
          } else if (e.which === 37) {
            moveToPrevious()
          }
        })
      } else {
        $(document).off('keydown')
      }
    })
  })
}
$(document).ready(function () {
  $('.c-carousel').each(function () {
    if ($(this).closest($('.u-template')).length === 0) {
      $(this).carousel()
    }
    window.view.reset();
    window.view.initialize();
  })
})

$.fn.onInViewport = function (func) {
  var $element = $(this)
  var inViewPort = function () {
    var offset = $element.offset()
    var size = {
      height: $element.height(),
      width: $element.width()
    }
    var windowsize = {
      height: $(window).height(),
      width: $(window).width()
    }
    var vertical = offset.top - windowsize.height < $(document).scrollTop() && $(document).scrollTop() < offset.top + size.height
    var horizontal = offset.left - windowsize.width < $(document).scrollLeft() && $(document).scrollLeft() < offset.left + size.width

    if (vertical && horizontal) {
      func(true)
    } else {
      func(false)
    }
  }
  $(document).scroll(function () {
    inViewPort()
  })
  $(window).resize(function () {
    inViewPort()
  })
  inViewPort()
}

$.fn.transform = function (pos) {
  if (typeof window._transformMethod === 'undefined') {
    var transform = 'left'
    var prefixes = 'transform WebkitTransform MozTransform OTransform msTransform'.split(' ')

    for (var i = 0; i < prefixes.length; i++) {
      if (document.createElement('div').style[prefixes[i]] !== undefined) {
        transform = '-' + prefixes[i].toLowerCase().split('tr').join('-tr')
        if (transform.substr(0, 2) === '--') {
          transform = transform.substr(2)
        }
        break
      }
    }
    window._transformMethod = transform
  }
  if (window._transformMethod !== 'left') {
    return $(this).css(window._transformMethod, 'translateX(' + pos + 'px)')
  }
  return $(this).css('left', pos + 'px')
}
$.fn.transition = function (pos) {
  if (typeof window._transitionMethod === 'undefined') {
    var prefixes = 'transition WebkitTransition MozTransition OTransition msTransition'.split(' ')
    var transition = 'none'
    for (var i = 0; i < prefixes.length; i++) {
      if (document.createElement('div').style[prefixes[i]] !== undefined) {
        transition = '-' + prefixes[i].toLowerCase().split('tr').join('-tr')
        if (transition.substr(0, 2) === '--') {
          transition = transition.substr(2)
        }
        break
      }
    }
    window._transitionMethod = transition
  }
  if (window._transitionMethod !== 'none') {
    return $(this).css(window._transitionMethod, pos)
  }
}

$.fn.moveLeft = function (pos, transition) {
  if (transition === undefined) {
    transition = ''
  }
  $(this).transition(transition).transform(pos)
}
